// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alexdezan-js": () => import("./../../../src/pages/alexdezan.js" /* webpackChunkName: "component---src-pages-alexdezan-js" */),
  "component---src-pages-benspeth-js": () => import("./../../../src/pages/benspeth.js" /* webpackChunkName: "component---src-pages-benspeth-js" */),
  "component---src-pages-christianlofwall-js": () => import("./../../../src/pages/christianlofwall.js" /* webpackChunkName: "component---src-pages-christianlofwall-js" */),
  "component---src-pages-crew-js": () => import("./../../../src/pages/crew.js" /* webpackChunkName: "component---src-pages-crew-js" */),
  "component---src-pages-darkdemon-js": () => import("./../../../src/pages/darkdemon.js" /* webpackChunkName: "component---src-pages-darkdemon-js" */),
  "component---src-pages-distro-js": () => import("./../../../src/pages/distro.js" /* webpackChunkName: "component---src-pages-distro-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-fanzine-js": () => import("./../../../src/pages/fanzine.js" /* webpackChunkName: "component---src-pages-fanzine-js" */),
  "component---src-pages-giovanidellamancha-js": () => import("./../../../src/pages/giovanidellamancha.js" /* webpackChunkName: "component---src-pages-giovanidellamancha-js" */),
  "component---src-pages-giovannademarchi-js": () => import("./../../../src/pages/giovannademarchi.js" /* webpackChunkName: "component---src-pages-giovannademarchi-js" */),
  "component---src-pages-giulia-js": () => import("./../../../src/pages/giulia.js" /* webpackChunkName: "component---src-pages-giulia-js" */),
  "component---src-pages-imok-js": () => import("./../../../src/pages/imok.js" /* webpackChunkName: "component---src-pages-imok-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inkjet-js": () => import("./../../../src/pages/inkjet.js" /* webpackChunkName: "component---src-pages-inkjet-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-sps-js": () => import("./../../../src/pages/sps.js" /* webpackChunkName: "component---src-pages-sps-js" */)
}

